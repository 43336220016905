import ReactDOM from 'react-dom/client';

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Toaster } from 'react-hot-toast';

import "./assets/style.css";

const App = lazy(() => import('./App'));
const ScanSignin = lazy(()=> import ('./ScanSignin'));
const AppContainer = lazy(() => import('./app-container/index'));

const Home = lazy(() => import('./app-container/home/index'));
const Account = lazy(() => import('./app-container/account/index'));

const LocationOutlet = lazy(()=>import('./app-container/locations/outlet'));
const Locations = lazy(()=>import('./app-container/locations/index'));
const LocationHistory = lazy(()=>import('./app-container/locations/history/index'));

const SensorOutlet = lazy(()=>import('./app-container/sensors/outlet'));
const Sensors = lazy(()=>import('./app-container/sensors/index'));
const ViewSensor = lazy(() => import('./app-container/sensors/view/index'));

const BinOutlet = lazy(()=>import('./app-container/bins/outlet'));
const Bins = lazy(()=>import('./app-container/bins/index'));

const PickupsOutlet = lazy(()=>import('./app-container/pickups/outlet'));
const Pickups = lazy(()=>import('./app-container/pickups/index'));
const PickupCreate = lazy(()=>import('./app-container/pickups/create/index'));

const ContactsOutlet = lazy(()=>import("./app-container/contacts/outlet"));
const Contacts = lazy(()=>import("./app-container/contacts/index"));

const SalesmanOutlet = lazy(()=>import('./app-container/salesman/outlet'));
const Salesman = lazy(()=>import('./app-container/salesman/index'));
const CreateSalesman = lazy(()=>import('./app-container/salesman/create/index'));

const DriversOutlet = lazy(()=>import('./app-container/drivers/outlet'));
const Drivers = lazy(()=>import('./app-container/drivers/index'));
const CreateDrivers = lazy(()=>import('./app-container/drivers/create/index'));

const PaymentsOutlet = lazy(()=>import("./app-container/payments/outlet"));
const Payments = lazy(()=>import("./app-container/payments/index"));

/*
fallback={
        <div className="w-full flex items-center justify-center" style={{ minHeight: '100vh' }}>
          <span className='spinning-logo-loader'>bins</span>
        </div>
      }
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Toaster toastOptions={{
      style: {
        backgroundColor: "var(--toast-background-color)",
        color: "var(--secondary-text-color)",
        borderRadius: '5px'
      }
    }}/>
    <Router>
      <Suspense >
        <Routes>
          <Route exact path="/" element={<App/>} />
          <Route path='/scan-signin' element={<ScanSignin/>}/>
          <Route path="app" element={<AppContainer/>}>
            <Route path="/app" element={<Home />}/>
            <Route path="account" element={<Account />}/>

            <Route path='locations' element={<LocationOutlet/>}>
              <Route path='' element={<Locations/>}/>
              <Route path='history/:id' element={<LocationHistory/>}/>
            </Route>

            <Route path='bins' element={<BinOutlet/>}>
              <Route path='' element={<Bins/>}/>
            </Route>

            <Route path='sensors' element={<SensorOutlet/>}>
              <Route path='' element={<Sensors/>}/>
              <Route path="view/:deviceid" element={<ViewSensor/>}/>
            </Route>
            
            <Route path='pickups' element={<PickupsOutlet/>}>
              <Route path='' element={<Pickups/>}/>
              <Route path='create' element={<PickupCreate/>}/>
            </Route>

            <Route path='accounts' element={<ContactsOutlet/>}>
              <Route path='' element={<Contacts/>}/>
            </Route>

            <Route path='salesman' element={<SalesmanOutlet/>}>
              <Route path='' element={<Salesman/>}/>
              <Route path='create' element={<CreateSalesman/>}/>
            </Route>

            <Route path='drivers' element={<DriversOutlet/>}>
              <Route path='' element={<Drivers/>}/>
              <Route path='create' element={<CreateDrivers/>}/>
            </Route>

            <Route path='payments' element={<PaymentsOutlet/>}>
              <Route path='' element={<Payments/>}/>
            </Route>

          </Route>
        </Routes>
      </Suspense>
    </Router>
  </>
);